export function dealStepTime (list) {
  const arr = []
  // 00:00-15:00
  for (let i = 0; i < list.length; i++) {
    // 起始小时
    const startH = +((i + 1) / 2).toFixed(0) - 1
    // 起始分钟 false 30   true 00
    const startM = i / 2 === +startH
    // 结束小时
    const endH = +((i + 2) / 2).toFixed(0) - 1
    // 结束分钟 false 30   true 00
    const endM = !startM
    // 起始时间点
    const startStr = `${('' + startH).padStart(2, '0')}:${startM ? '0' : '3'}0-`
    // 结束时间点
    const endStr = `${('' + endH).padStart(2, '0')}:${endM ? '0' : '3'}0`
    if (list[i - 1] && list[i] && list[i + 1]) {
      // 1 1 1
      continue
    } else if (!list[i - 1] && list[i] && list[i + 1]) {
      // 0 1 1
      arr.push(startStr)
    } else if (!list[i - 1] && list[i] && !list[i + 1]) {
      // 0 1 0
      arr.push(startStr + endStr)
    } else if (list[i - 1] && list[i] && !list[i + 1]) {
      // 1 1 _
      arr[arr.length - 1] = arr[arr.length - 1] + endStr
    } else if (!list[i]) {
      // x 0 x
      continue
    }
  }
  return arr
}
