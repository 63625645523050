<template>
  <div class="ad-plan-view-container">
    <div class="title">广告组</div>
    <div class="sub_title title">目标详情</div>
    <div class="prop_item">
      <span class="label">广告组名称</span>
      <span class="value"> {{ adGroupInfo.adPlanGroup.name }} </span>
    </div>
    <div class="prop_item">
      <span class="label">交互类型</span>
      <span class="value">
        {{
          adGroupInfo.adPlanGroup.actionType === 1
            ? '网页打开'
            : adGroupInfo.adPlanGroup.actionType === 2
              ? '下载'
              : adGroupInfo.adPlanGroup.actionType === 3
                ? '唤醒'
                : '--'
        }}
      </span>
    </div>
    <div class="prop_item_url">
      <div class="label">投放目标地址</div>
      <div class="value">{{ adGroupInfo.adPlanGroup.putTargetUrl }}</div>
    </div>
    <div class="prop_item_url" v-if="adGroupInfo.adPlanGroup.actionType === 3">
      <div class="label">唤醒地址</div>
      <div class="value">{{ adGroupInfo.adPlanGroup.deeplinkUrl }}</div>
    </div>
    <div class="prop_item_url" v-if="adGroupInfo.adPlanGroup.impressTrackUrl">
      <div class="label">展示检测URL</div>
      <div class="value">{{ adGroupInfo.adPlanGroup.impressTrackUrl }}</div>
    </div>
    <div class="prop_item_url" v-if="adGroupInfo.adPlanGroup.clickTrackUrl">
      <div class="label">点击检测URL</div>
      <div class="value">{{ adGroupInfo.adPlanGroup.clickTrackUrl }}</div>
    </div>
    <div class="title sub_title m-t-20">媒体选择</div>
    <div class="media_wrapper">
      <span
        v-for="(item, index) in placeList"
        :key="item.placeId"
      >{{ item.placeName }}{{ placeList.length === index + 1 ? '' : '， ' }}</span
      >
    </div>
    <div class="title sub_title m-t-20">定向设置</div>
    <!-- 地域 -->
    <div class="prop_container">
      <span class="label">地域</span>
      <div class="value_wrapper" v-if="areaList.length > 0">
        <span
          v-for="(item, index) in areaList"
          :key="item"
        >{{ item }}{{ areaList.length === index + 1 ? '' : '， ' }}</span
        >
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 平台 -->
    <div class="prop_container">
      <span class="label">平台</span>
      <div class="value_wrapper" v-if="platformList.length > 0">
        <span v-for="item in platformList" :key="item">{{ item === '0' ? 'IOS' : '安卓' }}</span>
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 设备类型 -->
    <div class="prop_container">
      <span class="label">设备类型</span>
      <div class="value_wrapper" v-if="equipment">
        <span>{{ equipment === '0' ? '智能手机' : '平板' }}</span>
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 网络类型 -->
    <div class="prop_container">
      <span class="label">网络类型</span>
      <div class="value_wrapper" v-if="networkList.length > 0">
        <span
          v-for="(item, index) in networkList"
          :key="item"
        >{{
          item === '1'
            ? 'Wi-Fi'
            : item === '2'
              ? '2G'
              : item === '3'
                ? '3G'
                : item === '4'
                  ? '4G'
                  : item === '5'
                    ? '5G'
                    : ''
        }}{{ networkList.length === index + 1 ? '' : '， ' }}</span
        >
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 运营商 -->
    <div class="prop_container">
      <span class="label">运营商</span>
      <div class="value_wrapper" v-if="operators.length > 0">
        <span
          v-for="(item, index) in operators"
          :key="item"
        >{{ item === '0' ? '移动' : item === '1' ? '联通' : item === '2' ? '电信' : ''
        }}{{ operators.length === index + 1 ? '' : '， ' }}</span
        >
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 安装列表 -->
    <div class="prop_container">
      <span class="label">安装列表</span>
      <div class="value_wrapper" v-if="installApps.length > 0">
        <div class="isclude">{{ installIsClude === 0 ? '定向已安装' : installIsClude === 1 ? '排除已安装' : '' }}</div>
        <span
          v-for="(item, index) in installApps"
          :key="item"
        >{{ item }}{{ installApps.length === index + 1 ? '' : '， ' }}</span
        >
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 手机品牌 -->
    <div class="prop_container">
      <span class="label">手机品牌</span>
      <div class="value_wrapper" v-if="phoneBrands.length > 0">
        <span
          v-for="(item, index) in phoneBrands"
          :key="item"
        >{{ item }}{{ phoneBrands.length === index + 1 ? '' : '， ' }}</span
        >
      </div>
      <span v-else>不限</span>
    </div>
    <div class="title sub_title m-t-20">排期与出价</div>
    <!-- 投放时段 -->
    <div class="prop_container">
      <span class="label">投放时段</span>
      <div class="step_time_wrapper" v-if="stepTimeIsClude">
        <div class="step_item">
          <div class="day"><span>星期一</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[0]">
              <span v-if="timeStepArr[0].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[0].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期二</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[1]">
              <span v-if="timeStepArr[1].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[1].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期三</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[2]">
              <span v-if="timeStepArr[2].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[2].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期四</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[3]">
              <span v-if="timeStepArr[3].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[3].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期五</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[4]">
              <span v-if="timeStepArr[4].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[4].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期六</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[5]">
              <span v-if="timeStepArr[5].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[5].length === 0">未选投放时段</span>
          </div>
        </div>
        <div class="step_item">
          <div class="day"><span>星期日</span></div>
          <div class="times">
            <template v-for="(item, index) in timeStepArr[6]">
              <span v-if="timeStepArr[6].length > 0" class="time_item" :key="index">{{ item }}</span></template
              >
            <span v-if="timeStepArr[6].length === 0">未选投放时段</span>
          </div>
        </div>
      </div>
      <span v-else>不限</span>
    </div>
    <!-- 日限额 -->
    <div class="prop_container">
      <span class="label">日限额</span>
      <div class="value_wrapper">
        <span>{{
          adGroupInfo.adPlanGroup.dayBudget !== 10000000 ? `￥${adGroupInfo.adPlanGroup.dayBudget}` : '不限'
        }}</span>
      </div>
    </div>
    <!-- 出价方式 -->
    <div class="prop_container">
      <span class="label">出价方式</span>
      <div class="value_wrapper">
        <span>{{ adGroupInfo.adPlanGroup.bidType === 0 ? `CPM` : '--' }}</span>
      </div>
    </div>
    <!-- 出价 -->
    <div class="prop_container">
      <span class="label">出价</span>
      <div class="value_wrapper">
        <span>￥{{ adGroupInfo.adPlanGroup.bidPrice }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { dealStepTime } from './dealStepTime'
export default {
  data () {
    return {
      placeList: [], // 广告位列表
      areaList: [], // 地区列表
      platformList: [], // 平台列表
      equipment: '', // 设备
      networkList: [], // 网络类型列表
      operators: [], // 运营商列表
      phoneBrands: [], // 品牌列表
      installApps: [], // 安装列表
      installIsClude: undefined, // 0包括 1不包括 2大于
      dateIsClude: undefined,
      throwdate: [], // 投放时间
      timeStepArr: [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ], // 投放时间段
      stepTimeIsClude: false
    }
  },
  props: {
    adGroupInfo: {
      type: Object,
      default: () => ({
        adPlanGroup: {}
      })
    }
  },
  watch: {
    adGroupInfo: {
      handler () {
        this.stepTimeIsClude = false
        if (this.adGroupInfo.adPlanPutOnPlaceResponseVoMap) {
          const placeList = []
          for (const key in this.adGroupInfo.adPlanPutOnPlaceResponseVoMap) {
            this.adGroupInfo.adPlanPutOnPlaceResponseVoMap[key].forEach((child) => {
              child.placeList.forEach((item) => {
                item.isChoosed && placeList.push(item)
              })
            })
          }
          this.placeList = placeList
        }
        if (this.adGroupInfo.adPlanGroupRuleLinkVoList) {
          this.adGroupInfo.adPlanGroupRuleLinkVoList.forEach((item) => {
            // 投放时间段
            if (+item.ruleId === 1) {
              this.timeStepArr = item.timeRuleContentVoList.map((item) => this.dealStepTime(item))
              this.stepTimeIsClude = true
            }
            // 地域
            if (+item.ruleId === 2) {
              this.areaList = item.ruleContentList
            }
            // 平台
            if (+item.ruleId === 3) {
              this.platformList = item.ruleContentList
            }
            // 设备类型
            if (+item.ruleId === 4) {
              this.equipment = item.ruleContentList[0]
            }
            // 网络类型
            if (+item.ruleId === 5) {
              this.networkList = item.ruleContentList
            }
            // 运营商
            if (+item.ruleId === 6) {
              this.operators = item.ruleContentList
            }
            // 手机品牌
            if (+item.ruleId === 7) {
              this.phoneBrands = item.ruleContentList
            }
            // 安装列表
            if (+item.ruleId === 8) {
              this.installApps = item.ruleContentList
              this.installIsClude = +item.ruleType
            }
            // 投放日期
            if (+item.ruleId === 9) {
              this.dateIsClude = +item.ruleType
              this.throwdate = item.ruleContentList
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    dealStepTime
  }
}
</script>

<style lang="less" scoped>
.ad-plan-view-container {
  margin-bottom: 30px;
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    &.sub_title {
      font-size: 14px;
    }
  }
  .prop_item {
    max-width: 500px;
    line-height: 40px;
    .label {
      display: inline-block;
      width: 100px;
    }
    .value {
      font-size: 13px;
      word-wrap: break-word;
    }
  }
  .prop_item_url {
    display: flex;
    width: 600px;
    margin-bottom: 20px;
    .label {
      width: 100px;
      flex: 0 0 auto;
    }
    .value {
      flex-grow: 1;
      word-break: break-all;
    }
  }
  .media_wrapper {
    width: 700px;
    padding: 0 10px 10px 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .prop_container {
    margin-bottom: 20px;
    display: flex;
    & > span {
      display: inline-block;
      width: 100px;
    }
    .value_wrapper {
      display: inline-block;
      width: 500px;
    }
    .step_time_wrapper {
      width: 700px;
      .step_item {
        display: flex;
        margin: 5px 10px 20px 10px;
        font-size: 12px;
        .day {
          margin-right: 15px;
          flex: 0 0 auto;
        }
        .times {
          display: flex;
          flex-wrap: wrap;
          .time_item {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.m-t-20 {
  margin-top: 20px;
}
</style>
